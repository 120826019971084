import printReportAction from '@/components/nsw-components/PrintReportsAction';
import DocumentNewVersionAction from '@/components/nsw-components/DocumentNewVersionAction';

export default {
	actions: [
		printReportAction.printReport('DRIVER_LIST', 'imofalformid', 'reports.label.falForms.driverlist'),
		DocumentNewVersionAction.newVersion({
			imofalformtypeid: 11
		})
	]
};
